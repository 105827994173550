import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';

export default class Activities extends Component {
    render() {
        return (
            <section className="services">
                <Title title="Activities" />
                <div className="services-center">
                            <span className="services">
                            
                               <Hero hero="marketHero" />
                                
                            </span>
                            <span className="services">
                            
                               <Hero hero="movienightHero" />
                                
                            </span>
                            <span className="services">
                            
                               <Hero hero="bbqHero" />
                                
                            </span>
                </div>
            </section>
        )
    }
}
