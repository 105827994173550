import React, { Component } from 'react'
import Hero from './Hero';

export default class CoworkImages extends Component {
    render() {
        return (
            <section className="services">
                <div className="services-center">
                            <span className="services">
                            
                               <Hero hero="coWorkSmall1Hero" />
                                
                            </span>
                            <span className="services">
                            
                               <Hero hero="coWorkSmall2Hero" />
                                
                            </span>
                            <span className="services">
                            
                               <Hero hero="coWorkSmall3Hero" />
                                
                            </span>
                </div>
            </section>
        )
    }
}
