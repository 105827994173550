import React from 'react'
import Title from './Title'
import Hero from './Hero';

const Menu = () => {
    return (
        <section className="services">
                <Title title="The GreenSpace Bistro Menu" />
                <div className="services-center">
                            <span >
                            
                               <Hero hero="menu1LargeHero" />
                                
                            </span>
                            <span>
                            
                               <Hero hero="menu2LargeHero" />
                                
                            </span>
                            <span>
                            
                               <Hero hero="menu3LargeHero" />
                                
                            </span>
                            <span>
                            
                               <Hero hero="menu4LargeHero" />
                                
                            </span>
                            <span>
                            
                               <Hero hero="menu5LargeHero" />
                                
                            </span>
                            <span>
                            
                               <Hero hero="menu6LargeHero" />
                                
                            </span>
                            <span>
                            
                               <Hero hero="menu7LargeHero" />
                                
                            </span>
                        
                </div>
            </section>
    )
}

export default Menu
