import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';

export default class TextImageRightBlogger extends Component {
    state={
        services:
            {
                title:"Our Vision",
                info:`Driven by values to help achieving a greener future for all, We live by the philosophy of eco tourism. We at GreenSpace are trying to bring like minded people and businesses together to drive a new green revolution among the backpacker community. Collectively, we can move away 
                from what harms our environment to a more sustainable way of
                travel and living. Let's try to build an ecosystem of green sustainable travelling.`,
                title2:"HELP US TELL OUR STORY"
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="Are you a Travel Blogger?" />
                <div className="services-center">
                            <span className="services">
                            
                               <Hero hero="bloggerLargeHero" />
                                
                            </span>
                            <span className="services">
                                <div className="services">
                                <h6>{this.state.services.title2}</h6>
                                <ul className="cowork-list">
                                        <li> CONTACT US AT</li>
                                        <li>greenspacegoa@gmail.com</li>
                                        <li>+919049905310</li>
                                        <li>Instagram: greenspacegoa</li>
                                        <li>Facebook: greenspacegoa</li>
                                </ul>
                                </div>
                                <div className="services">
                                <h6>{this.state.services.title}</h6>
                                <p>{this.state.services.info}</p>
                                </div>
                            </span>
                </div>
            </section>
        )
    }
}
