import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';

export default class TextImageLeftAqua extends Component {
    state={
        services:
            {
                title:"Aquaponics",
                info:`Aquaponics is a combination of aquaculture, which is growing fish and other aquatic animals, 
                and hydroponics which is growing plants without soil. Aquaponics uses these two in a symbiotic combination 
                in which plants are fed the aquatic animals’ discharge or waste. 
                In return, the vegetables clean the water that goes back to the fish.
                Along with the fish and their waste, microbes play an important role to the nutrition of the plants. 
                These beneficial bacteria gather in the spaces between the roots of the plant and converts the fish waste and the solids 
                into substances the plants can use to grow. 
                The result is a perfect collaboration between aquaculture and gardening.
                 `
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="Aquaponics" />
                <div className="services-center">
                            <span className="service">
                                
                                <Hero hero="aquaLargeHero" />
                                
                            </span>
                            <span className="service">
                                <div className="services"> 
                                     <h6>{this.state.services.title}</h6>
                                     <p>{this.state.services.info}</p>
                                </div>
                            </span>  
                            
                        
                </div>
            </section>
        )
    }
}
