import React from 'react';
import './App.css';
import Home from './pages/Home';
import Error from './pages/Error';
import Menu from './components/Menu'
import {Route, Switch} from 'react-router-dom';
import Navbar from './components/Navbar'
import {Helmet} from 'react-helmet'

function App() {
  return (
    <>
      <Helmet>
        <title>Greenspace</title>
        <meta name="description" content="Green Space Camp and Co-working Hostel is located in Anjuna in the Goa region, 5.5 km from Anjuna Flea Market and 1.9 km from St. Michael's Church.
        The whole idea of naming it Green Space was to inculcate the habit of being eco-friendly and providing a safe haven for nature lovers. We are committed to offering you an amazing experience here with our concept of camping. With an old Portuguese house as backdrop, the camps are set in the garden with fully functional common areas, washrooms and other amenities.

        An in-house cafe, Natti's Naturals,  serving organic and healthy food complements our vision.
        
        With an array of activities planned throughout the week, we will make sure that you love every moment of your stay here.
        
        Let the greenness inside you flow out into the space." />
        <meta name="keywords" content="Greenspace,Green Space,Greenspacegoa,Green,Space,Goa,Vagator,Anjuna,Yeshrupa,Camp,Hostel,Dormitory,Mixed-Dormitory,mixed dormitory,tent,garden view,Co-working,coworking,Cafe,Wifi,fast Wifi,eco-friendly,eco friendly,Natti's Naturals,portuguese house,cleanliness,greenliness,backpackers,nature,nature-lovers,travellers,
        Greenness,organic,healthy,food,Tattoo,cheap,accomodation,value for money,Hopping Frog,Green,eco-friendly products,activities,bar hopping,common area,washrooms,best reviews, Saneesh, Ishan, Narendra" />
      </Helmet>
      <Navbar/>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/menu" component={Menu} />
        <Route component={Error}/>
      </Switch>
    </>
  );
}

export default App;
