import React , { useRef,useEffect}from 'react';
import Services from '../components/Services'
import TextImageRightgasl from '../components/TextImageRightgasl'
import HuhyTravel from '../components/Huhytravel'
import TextImageRightCamp from '../components/TextImageRightCamp'
import TextImageLeftCowork from '../components/TextImageLeftCowork'
import HostelImages from '../components/HostelImages'
import CoworkImages from '../components/CoworkImages'
import TextImageLeftCWS from '../components/TextImageLeftCWS'
import Activities from '../components/Activities'
import TextImageRightProduct from '../components/TextImageRightProduct'
import TextImageLeftCafe from '../components/TextImageLeftCafe'
import CafeImages from '../components/CafeImages'
import TextImageRightBlogger from '../components/TextImageRightBlogger'
import TextImageLeftVolunteer from '../components/TextImageLeftVolunteer'
import AboutUs from '../components/AboutUs'
import Offers from '../components/Offers'
import Ambassador from '../components/Ambassodor'
import AllImages from '../components/AllImages'
import '../components/Slider.css'
import Carousel from 'nuka-carousel';
import imgMomos from '../images/momos.webp';
import imgAqua from '../images/Aquaponics.webp';
import imgBistro from '../images/Bistro.webp';
import imgHospita from '../images/GreenLivingWeb.webp';
import imgProduct from '../images/ProductNew1.webp';
import imgConsulting from '../images/VolunteerLargeWeb.webp'
import imgYogaWeb  from '../images/YogaWeb.webp'
import ProductImages from '../components/ProductImages';
import TextImageLeftAqua from '../components/TextImageLeftAqua';
import AquaImages from '../components/AquaImages';
import TextImageRightConsult from '../components/TextImageRightConsult';
import TextImageLeftFood from '../components/TextImageLeftFood';
import AOS from "aos";
import "aos/dist/aos.css";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   
const scrollToRef1 = (ref) => window.scrollTo(0, ref.current.offsetTop)   
const scrollToRef2 = (ref) => window.scrollTo(0, ref.current.offsetTop)   
const scrollToRef3 = (ref) => window.scrollTo(0, ref.current.offsetTop)   
const scrollToRef4 = (ref) => window.scrollTo(0, ref.current.offsetTop)   
const scrollToRef5 = (ref) => window.scrollTo(0, ref.current.offsetTop)
const scrollToRef6 = (ref) => window.scrollTo(0, ref.current.offsetTop)

const Home = () => {

    const bistroRef = useRef(null)
    const activityRef = useRef(null)
    const productRef = useRef(null)
    const hostelRef = useRef(null)
    const consultRef = useRef(null)
    const foodRef = useRef(null)
    const aquaRef = useRef(null)

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);

    return (
        <>
        
        <div>
            <Carousel heightMode="max" autoplay={true} width="100%" wrapAround={true} pauseOnHover={true}
            swiping={true} withoutControls={false}    
            easing="easeInOutElastic" edgeEasing="easeOutCirc" speed={300} 
            
            defaultControlsConfig={{
                nextButtonText: 'Next',
                prevButtonText: 'Prev',
                pagingDotsContainerClassName: "carousel_pagingDotsContainer",
                pagingDotsClassName: "carousel_pagingDots",
                pagingDotsStyle: {
                    fill: 'red'
                }
            }}                                                                                                                                                                                                           
            >
                <div className="slider__imgwrap" onMouseDown={()=>scrollToRef(bistroRef)}>
                    <img  src={imgBistro} alt=""/>
                    <span >BISTRO</span>
                </div>
                <div className="slider__imgwrap" onMouseDown={()=>scrollToRef1(aquaRef)}>
                    <img  src={imgAqua} alt=""/>
                    <span>AQUAPONICS</span>
                </div>
                <div className="slider__imgwrap" onMouseDown={()=>scrollToRef2(productRef)}>
                    <img  src={imgProduct} alt=""/>
                    <span>PRODUCTS</span>
                </div>
                <div className="slider__imgwrap" onMouseDown={()=>scrollToRef3(hostelRef)}>
                    <img  src={imgHospita} alt=""/>
                    <span>HOSTEL</span>
                </div>
                <div className="slider__imgwrap" onMouseDown={()=>scrollToRef4(consultRef)}>
                    <img  src={imgConsulting} alt=""/>
                    <span>CONSULTANCY</span>
                </div>
                <div className="slider__imgwrap" onMouseDown={()=>scrollToRef5(foodRef)}>
                    <img  src={imgMomos} alt=""/>
                    <span>PACKAGED FOOD</span>
                </div>
                <div className="slider__imgwrap" onMouseDown={()=>scrollToRef6(activityRef)}>
                    <img  src={imgYogaWeb} alt=""/>
                    <span>WELLNESS & SPORTS</span>
                </div>
            </Carousel>
        </div> 
       
        <div>
        <TextImageRightCamp />
        <TextImageLeftCowork />
        </div>
        <div ref={bistroRef}>
            <div>
                <TextImageLeftCafe />
            </div>
            <div>
                <CafeImages />
            </div>
        </div>
        <div ref={aquaRef}>
            <TextImageLeftAqua />
            <AquaImages />
        </div>
        <div ref={productRef}>
        <TextImageRightProduct />
        <ProductImages />
        </div>
        <div ref={hostelRef}>
        <HostelImages />
        <TextImageLeftCWS />
        <CoworkImages />
        <Offers />
        <TextImageRightgasl />
        </div>
        
        <div ref={activityRef}>
        <Activities /> 
        <TextImageRightBlogger />
        <Services />
        <TextImageLeftVolunteer />
        </div>
        <div ref={consultRef}>
        <TextImageRightConsult />
        </div>
        <div ref={foodRef}>
            <TextImageLeftFood />
        </div>
        <HuhyTravel />
        <AboutUs />
        <Offers />
        <Ambassador />
        <AllImages />
        </>
    )
}

export default Home
