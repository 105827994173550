import React, { Component } from 'react'
import Title from './Title'

export default class Ambassador extends Component {
    state={
        services:
            {
                title:"GET IN TOUCH",
                info:`H.no. 1654, Ozran Beach Road, Vagator, Goa.
                 `
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="BECOME A GREENSPACE AMBASSADOR AND EARN ON THE GO!!" />
                <div className="services-center">
                            <span className="service">
                                <div className="services-alt"> 
                                    <h6>{this.state.services.title}</h6>
                                     <p>{this.state.services.info}</p>
                                     <ul className="cowork-list">
                                        <li>greenspacegoa@gmail.com</li>
                                        <li>+919776768787</li>
                                        <li>Instagram: greenspacegoa</li>
                                        <li>Facebook: greenspacegoa</li>
                                    </ul>
                                </div>
                            </span>
                </div>
            </section>
        )
    }
}
