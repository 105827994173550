import React, { Component } from 'react'
import Title from './Title'

export default class AboutUs extends Component {
    state={
        services:
            {
                info:`We are a young company, driven by values to help achieving a greener future for all. We live by the philosophy of eco tourism. We at GreenSpace are trying to bring like minded people and businesses together to drive a new green revolution among the backpacker community. Collectively, we can move away from what harms our environment to a more sustainable way of
                travel and living. Let's try to build an ecosystem of green sustainable travelling.
                 `
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="About Us" />
                <div className="services-center">
                            <span className="service">
                                <div className="services-alt"> 
                                     <p>{this.state.services.info}</p>
                                </div>
                            </span>
                </div>
            </section>
        )
    }
}
