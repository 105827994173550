import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';

export default class TextImageRightCamp extends Component {
    state={
        services:
            {
                title:"Green Space Camp & Hostel",
                info:`
                GreenSpace's aim is to help achieve a greener future for all and live by the philosophy of eco tourism. We are committed 
                to offering you an amazing experience here with our concept of camping. With an old Portuguese house as backdrop,
                 the camps are set in the garden with fully functional common areas and other amenities.
                 `,
                 coworktitle:"Co-working Space",
                coworkinfo:`Creative | Fun | Green Co-Working Space in Goa, with fast Wifi Connection, power backup, multi plug, reading light,
                 dedicated table, comfortable seating, stationary.
                 `
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="BE ONE WITH NATURE" />
                <div className="services-center">
                            <span className="service">
                            
                               <Hero hero="hostelHero" />
                                
                            </span>
                            <span className="service">
                                <div className="services"> 
                                     <h6>{this.state.services.title}</h6>
                                     <p>{this.state.services.info}</p>
                                </div>
                            </span>
                            <span className="service">
                            
                               <Hero hero="coworkHero" />
                                
                            </span>
                            <span className="service">
                                <div className="services"> 
                                     <h6>{this.state.services.coworktitle}</h6>
                                     <p>{this.state.services.coworkinfo}</p>
                                </div>
                            </span>
                </div>
            </section>
        )
    }
}
