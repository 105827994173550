import React, { Component } from 'react'
import Hero from './Hero';

export default class CafeImages extends Component {
    render() {
        return (
            <section className="services">
                <div className="services-center">
                            <span className="services">
                            
                               <Hero hero="cafeSmall1Hero" />
                                
                            </span>
                            <span className="services">
                            
                               <Hero hero="cafeSmall2Hero" />
                                
                            </span>
                            <span className="services">
                            
                               <Hero hero="cafeSmall3Hero" />
                                
                            </span>
                </div>
            </section>
        )
    }
}
