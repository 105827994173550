import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';

export default class Huhytravel extends Component {
    state={
        services:
            {
                
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="Help us Help You Travel" />
                <div className="services-center">
                            <span className="services">
                            
                               <Hero hero="huhytHero" />
                                
                            </span>
                </div>
            </section>
        )
    }
}
