import React, { Component } from 'react'
import imgGreenLivingWeb from '../images/GreenLivingWeb.webp'
import imgCoworkLargeWeb from '../images/CoworkLargeWeb.webp'
import imgGreenandSustainableLiving from '../images/GreenandSustainableLiving.webp'
import imgHostelWeb from '../images/HostelWeb.webp'
import imgcoworkWeb from '../images/coworkWeb.webp'
import imgProductWeb from '../images/ProductWeb.webp'
import imgCafeWeb from '../images/CafeWeb.webp'
import imgMovieNightWeb from '../images/MovieNightWeb.webp'
import imgBBQWeb from '../images/BBQWeb.webp'
import imgYogaWeb from '../images/YogaWeb.webp'
import imgTentSmallWeb from '../images/TentSmallWeb.webp'
import imgDormSmall2Web from '../images/DormSmall2Web.webp'
import imgPRSmall1Web from '../images/PRSmall1Web.webp'
import imgCoworkSmall2Web from '../images/CoworkSmall2Web.webp'
import imgCoworkSmall3Web from '../images/CoworkSmall3Web.webp'
import imgCoworkSmall1Web from '../images/CoworkSmall1Web.webp'
import imgCafeColl1Web from '../images/Bistro1.webp'
import imgCafeColl2Web from '../images/Bistro4.webp'
import imgCafeColl6Web from '../images/Bistro3.webp'
import imgCafeCollLargeWeb from '../images/Bistro.webp'
import imgBloggerLargeWeb from '../images/BloggerLargeWeb.webp'
import imgVolunteerLargeWeb from '../images/VolunteerLargeWeb.webp'
import imgProductlargeWeb from '../images/ProductlargeWeb.webp'

export default class AllImages extends Component {
    render() {
        return (
            <div style={{display: "none"}}>
                <img src={imgGreenLivingWeb} alt="Green Space Camp and Co-working Hostel - Green and Sutainable Living" />
                <img src={imgCoworkLargeWeb} alt="Green Space Camp and Co-working Hostel - Co-Working Space" />
                <img src={imgGreenandSustainableLiving} alt="Green Space Camp and Co-working Hostel - Green and Sutainable Living" />
                <img src={imgHostelWeb} alt="Green Space Camp and Co-working Hostel - Hostel" />
                <img src={imgcoworkWeb} alt="Green Space Camp and Co-working Hostel - Co-Working Space" />
                <img src={imgProductWeb} alt="Green Space Camp and Co-working Hostel - Eco-friendly products" />
                <img src={imgCafeWeb} alt="Green Space Camp and Co-working Hostel - Natti's Naturals Cafe" />
                <img src={imgMovieNightWeb} alt="Green Space Camp and Co-working Hostel - Movie Night" />
                <img src={imgBBQWeb} alt="Green Space Camp and Co-working Hostel - Barbecue" />
                <img src={imgYogaWeb} alt="Green Space Camp and Co-working Hostel - Yoga" />
                <img src={imgTentSmallWeb} alt="Green Space Camp and Co-working Hostel - Deluxe Tents" />
                <img src={imgDormSmall2Web} alt="Green Space Camp and Co-working Hostel - Mixed Dormitory" />
                <img src={imgPRSmall1Web} alt="Green Space Camp and Co-working Hostel - Deluxe Private Room" />
                <img src={imgCoworkSmall2Web} alt="Green Space Camp and Co-working Hostel - Co-Working Space" />
                <img src={imgCoworkSmall3Web} alt="Green Space Camp and Co-working Hostel - Co-Working Space" />
                <img src={imgCoworkSmall1Web} alt="Green Space Camp and Co-working Hostel - Co-Working Space" />
                <img src={imgCafeColl1Web} alt="Green Space Camp and Co-working Hostel - Natti's Naturals Cafe" />
                <img src={imgCafeColl2Web} alt="Green Space Camp and Co-working Hostel - Natti's Naturals Cafe" />
                <img src={imgCafeColl6Web} alt="Green Space Camp and Co-working Hostel - Natti's Naturals Cafe" />
                <img src={imgCafeCollLargeWeb} alt="Green Space Camp and Co-working Hostel - Natti's Naturals Cafe" />
                <img src={imgBloggerLargeWeb} alt="Green Space Camp and Co-working Hostel - Are You A Travel Blogger? Help Us Tell our Story " />
                <img src={imgVolunteerLargeWeb} alt="Green Space Camp and Co-working Hostel - Volunteer For Us" />
                <img src={imgProductlargeWeb} alt="Green Space Camp and Co-working Hostel - Eco-friendly products" />
            </div>
        )
    }
}
