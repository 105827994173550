import React, { Component } from 'react'
import Title from './Title'
import {FaHiking, FaShuttleVan, FaBeer} from 'react-icons/fa'
import {MdLocalLaundryService, MdLocalBar,MdMovie} from 'react-icons/md'
import {GiBarbecue, GiFriedFish} from 'react-icons/gi'

export default class Services extends Component {

    state={
        services:[
            {
                icon:<MdLocalBar size={64}/>,
                title:"Bar Hopping",
                info:"Explore the best parties and food joints in the town"
            },
            {
                icon:<FaHiking size={64}/>,
                title:"Endless Hiking",
                info:"Hike to Chapora Fort, Ozran beach and other awesome spots near by"
            },
            {
                icon:<GiBarbecue size={64}/>,
                title:"Barbecue",
                info:"Enjoy weekly barbecue nights"
            },
            {
                icon:<MdMovie size={64}/>,
                title:"Movie Night",
                info:"Enjoy movie nights and indoor games"
            },
            {
                icon:<GiFriedFish size={64}/>,
                title:"Fish Thali",
                info:"Visit local Goan homes and enjoy fish thali and home made local food"
            },
            {
                icon:<MdLocalLaundryService size={64}/>,
                title:"Laundry Services",
                info:"Self / Assited laundry services available at minimal cost"
            },
            {
                icon:<FaShuttleVan size={64}/>,
                title:"Transport",
                info:"Airport shuttles, Taxi services - local / out of station can be arranged on request"
            },
            {
                icon:<FaBeer size={64}/>,
                title:"Strongest Beer",
                info:"Enjoy the environment with a chilled beer"
            }
        ]
    }
    render() {
        return (
            <section className="services">
                <Title title="Services" />
                <div className="services-center">
                    {this.state.services.map((item, index) => {
                        return(
                            <article key={index} className="services-alt">
                                <span style={{color: '#FF652F'}}>{item.icon}</span>
                                <h6>{item.title}</h6>
                                <p>{item.info}</p>
                            </article>
                        );
                    }

                    )}
                </div>
            </section>
        )
    }
}
