import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';

export default class TextImageLeftFood extends Component {
    state={
        services:
            {
                title:"Packaged Food",
                info:`Need Description
                 `
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="Packaged Food" />
                <div className="services-center">
                            <span className="service">
                                <div className="services"> 
                                     <h6>{this.state.services.title}</h6>
                                     <p>{this.state.services.info}</p>
                                </div>
                            </span>
                            <span className="service">
                            
                               <Hero hero="foodLargeHero" />
                                
                            </span>
                            
                        
                </div>
            </section>
        )
    }
}
