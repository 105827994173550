import React, { Component } from 'react'
import Title from './Title'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram,
    faGoogle
  } from "@fortawesome/free-brands-svg-icons";
import BookingIcon from '../images/bookingiconlarge.svg'
import HWIcon from '../images/hwiconlarge.svg'

export default class Offers extends Component {
    render() {
        return (
            <section className="services">
                <Title title="Offers" />
                <div className="services-center">
                    
                            <article className="services-alt">
                                
                                <div className="social-container-alt">
                                <a title="Book your stay with us on Booking.com"
                                    href="https://www.booking.com/hotel/in/hopping-frog-boutique-guesthouse.en-gb.html"
                                    target="_blank" className="social" rel="noopener noreferrer"
                                    >
                                    <img src={BookingIcon} alt="Booking.com"/>
                                    </a>
                                    <a title="Book your stay with us on HostelWorld.com"
                                    href="https://www.hostelworld.com/hosteldetails.php/Green-Space-Camp-and-Co-working/Goa/296210"
                                    target="_blank" className="social" rel="noopener noreferrer"
                                    >
                                    <img src={HWIcon} alt="HostelWorld.com"/>
                                </a>
                                </div>
                               
                                <h6>REVIEW YOUR STAY</h6>
                                <p>25% discount on your next stay with us for a positive review on platforms like Booking.com, Hostelworld etc.</p>
                            </article>
                            <article className="services-alt">
                                <div className="social-container-alt">
                                    <a title="Review us on Google"
                                    href="https://www.google.com/search?authuser=2&sxsrf=ACYBGNRziwRMRbygkAd_BXQSTJeZeYCXZw%3A1569758578917&ei=cp2QXdO_N_HA3LUP2uu4kAw&q=green+space+camp+and+coworking+space+goa&oq=green+space+camp+and+coworking+space+goa&gs_l=psy-ab.3..33i21.4781.6225..6432...0.0..0.144.510.0j4......0....1..gws-wiz.......33i160.8H8PtcwaK4M&ved=0ahUKEwjTwOqz_vXkAhVxILcAHdo1DsIQ4dUDCAs&uact=5#lrd=0x3bbfe9a16984702d:0x385904350858950d,1,,," target="_blank"
                                    className="google social" rel="noopener noreferrer"
                                    >
                                    <FontAwesomeIcon icon={faGoogle} size="4x" />
                                    </a>
                                    <a title="Review us on Trip Advisor"
                                    href="https://www.tripadvisor.in/Hotel_Review-g1204883-d17514308-Reviews-Green_Space_Camp_n_Co_working_Hostel-Vagator_North_Goa_District_Goa.html"
                                    target="_blank" className="tripadvisor social" rel="noopener noreferrer"
                                    >
                                    <FontAwesomeIcon icon={faInstagram} size="4x" />
                                    </a>
                                </div>
                                <h6>REVIEW US ON GOOGLE, TRIPADVISOR</h6>
                                <p>A refreshing free drink for every positive review.</p>
                            </article>
                            <article className="services-alt">
                                <div className="social-container-alt">
                                    <a title="Follow us on Facebook"
                                    href="https://www.facebook.com/Greenspacegoa-409206276481931/" target="_blank" rel="noopener noreferrer"
                                    className="facebook social"
                                    >
                                    <FontAwesomeIcon icon={faFacebook} size="4x" />
                                    </a>
                                    <a title="Follow us on Instagram"
                                    href="https://www.instagram.com/greenspacegoa/" target="_blank" rel="noopener noreferrer"
                                    className="instagram social"
                                    >
                                    <FontAwesomeIcon icon={faInstagram} size="4x" color="#856b39"/>
                                    </a>
                                </div>
                                <h6>FOLLOW US ON FACEBOOK, INSTAGRAM</h6>
                                <p>A refreshing free drink for every positive review.</p>
                            </article>
                </div>
            </section>
        )
    }
}
