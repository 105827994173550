import React, { Component } from 'react'
import Hero from './Hero';

export default class AquaImages extends Component {
    render() {
        return (
            <section className="services">
                <div className="services-center">
                            <span className="services">
                            
                               <Hero hero="aquaSmall1Hero" />
                                
                            </span>
                            <span className="services">
                            
                               <Hero hero="aquaSmall2Hero" />
                                
                            </span>
                            <span className="services">
                            
                               <Hero hero="aquaSmall3Hero" />
                                
                            </span>
                </div>
            </section>
        )
    }
}
