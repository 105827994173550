import React, { Component } from 'react'
import Hero from './Hero';

export default class TextImageLeftCowork extends Component {
    state={
        services:
            {
                producttitle:"Eco-friendly Products",
                productinfo:`Consistent with our philosophy of eco tourism, you can avail Eco-friendly products for your travel and daily use.
                Greenspace has a wide range of eco-friendly products such as travel tool kits, bamboo straws, recycled newspaper pencils, seed pencils, Dairies made 
                out of upcycled paper, Natural soaps, shampoos, face washes etc
                 `,
                cafetitle:"Greenspace Bistro",
                cafeinfo:`The in-house Greenspace Bistro, serves organic and healthy food, that complements our vision, 
                as a hub for health and happiness. Greenspace Bistro offers a wide range of dishes, to suite our customers and their tastes . 
                the Bistro offers variety of Authentic Napolitano Artisanal Pizza , tasty burgers, dumplings, smoothies etc.`
            }
        
    }
    render() {
        return (
            <section className="services">
                <div className="services-center">
                            
                            <span className="service">
                                <div className="services"> 
                                     <h6>{this.state.services.producttitle}</h6>
                                     <p>{this.state.services.productinfo}</p>
                                </div>
                            </span>
                            <span className="service">
                            
                               <Hero hero="productHero" />
                                
                            </span>
                            <span className="service">
                                <div className="services"> 
                                     <h6>{this.state.services.cafetitle}</h6>
                                     <p>{this.state.services.cafeinfo}</p>
                                </div>
                            </span>
                            <span className="service">
                            
                               <Hero hero="cafeHero" />
                                
                            </span>
                </div>
            </section>
        )
    }
}
