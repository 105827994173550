import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';

export default class HostelImages extends Component {
    render() {
        return (
            <section className="services">
                <Title title="The Hostel" />
                <div className="services-center">
                            <span className="services">
                            <h6>Tents</h6>
                               <Hero hero="tentSmallHero" />
                                
                            </span>
                            <span className="services">
                            <h6>Mixed Dormitory</h6>
                               <Hero hero="dormSmallHero" />
                                
                            </span>
                            <span className="services">
                            <h6>Deluxe Room</h6>
                               <Hero hero="PRSmallHero" />
                                
                            </span>
                </div>
            </section>
        )
    }
}
