import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';

export default class TextImageLeftCafe extends Component {
    state={
        services:
            {
                title:"The Greenspace Bistro",
                info:`The in-house Greenspace Bistro, serves organic and healthy food complements our vision. 
                As a hub for health and happiness, Greenspace Bistro offers a wide range of dishes in the cafe to suite our customers and their tastes . 
                the Cafe offers variety of Authentic Napolitano Artisanal Pizza , tasty burgers, dumplings, smoothies etc.
                Everything is locally sourced.
                 `
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="The Greenspace Bistro" />
                <div className="services-center">
                            <span className="service">
                            
                                <Hero hero="cafeLargeHero" />
                             
                            </span>
                            <span className="service">
                                <div className="services"> 
                                     <h6>{this.state.services.title}</h6>
                                     <p>{this.state.services.info}</p>
                                </div>
                            </span>
                            
                </div>
            </section>
        )
    }
}
