import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';

export default class TextImageLeftVolunteer extends Component {
    state={
        services:
            {
                title:"Volunteer for us managing our front desk or organizing events and earn your free stay with us, while in Goa"
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="Volunteer For Us!" />
                <div className="services-center">
                            <span className="service">
                                <div className="services"> 
                                     <p>{this.state.services.title}</p>
                                     <br />
                                     <ul className="cowork-list">
                                        <li> CONTACT US AT</li>
                                        <li>greenspacegoa@gmail.com</li>
                                        <li>+919049905310</li>
                                        <li>Instagram: greenspacegoa</li>
                                        <li>Facebook: greenspacegoa</li>
                                    </ul>
                                </div>
                            </span>
                            <span className="service">
                            
                               <Hero hero="volunteerLargeHero" />
                                
                            </span>
                            
                        
                </div>
            </section>
        )
    }
}
