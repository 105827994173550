import React, { Component } from 'react';
import logo from '../images/logo.svg';
import {FaAlignRight} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import FollowUs from '../components/FollowUs'
import {MdLocalPhone, MdEmail} from 'react-icons/md'

export default class Navbar extends Component {
    state={
        isOpen:false
    }

    handleToggle = () => {
        this.setState({isOpen: !this.state.isOpen})
    }

    render() {
        
        return (
            <nav className="navbar">
                <div className="nav-center">
                    <div className="nav-header">
                        <Link to="/">
                            <img src={logo} alt="Greenspace"/>
                        </Link>
                        <button type="button" 
                        className="nav-btn" 
                        onClick={this.handleToggle}>
                            <FaAlignRight className="nav-icon" />
                        </button>
                    </div>
                    <ul className={this.state.isOpen?"nav-links show-nav":"nav-links"}>
                        
                        <li>
                            <FollowUs />
                        </li>
                        <li>
                        <div>
                            <span style={{color: '#FF652F'}}>
                                <MdEmail size={15}/>
                            </span>
                            <h7> : greenspacegoa@gmail.com</h7>
                        </div>
                        <div>
                            <span style={{color: '#FF652F'}}><MdLocalPhone size={15}/></span>
                            <h7> : +919776768787</h7>
                        </div>
                        </li>
                    </ul>
                   
                </div>

            </nav>
        )   ;
    }
}
