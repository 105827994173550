import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';

export default class TextImageLeftCWS extends Component {
    state={
        services:
            {
                title:"Creative | Fun | Green Co-Working Space",
                info:`Green Space Camp & Co-working Hostel is located in Anjuna in the Goa region,
                 5.5 km from Anjuna Flea Market and 1.9 km from St. Michael's Church.
                 `
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="Co-working Space" />
                <div className="services-center">
                            <span className="service">
                                <div className="services"> 
                                     <h6>{this.state.services.title}</h6>
                                     <ul className="cowork-list">
                                         <li>Fast Wifi Connection</li>
                                         <li>Power Backup</li>
                                         <li>Dedicated Table</li>
                                         <li>Multi Plug</li>
                                         <li>Reading Light</li>
                                         <li>Stationary</li>
                                         <li>Meeting Area</li>
                                         <li>Comfortable Seating</li>
                                         <li>Printer / Scanner</li>
                                         <li>Whiteboard</li>
                                         <li>Projector on Request</li>
                                         <li>Cafe (Natti's Naturals)</li>
                                     </ul>
                                     <br />
                                     <ul className="cowork-list">
                                        <li>250 INR for 4 hours</li>
                                        <li>350 INR for 8 hours</li>
                                        <li>5000 INR for month</li>
                                     </ul>
                                     <br/>
                                     <ul className="cowork-list">
                                        <li> CONTACT US AT</li>
                                        <li>greenspacegoa@gmail.com</li>
                                        <li>+919049905310</li>
                                     </ul>
                                </div>
                            </span>
                            <span className="service">
                            
                               <Hero hero="coworkLargeHero" />
                                
                            </span>
                            
                        
                </div>
            </section>
        )
    }
}
