import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';

export default class TextImageRightConsult extends Component {
    state={
        services:
            {
                title:"Consultancy",
                info:`Need description`,
                info2:`Need description`
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="Consultancy" />
                <div className="services-center">
                            <span className="services">
                            
                               <Hero hero="productLargeHero" />
                                
                            </span>
                            <span className="services">
                                <div className="services">
                                <h6>{this.state.services.title}</h6>
                                <p>{this.state.services.info}</p>
                                <br />
                                <p>{this.state.services.info2}</p>
                                </div>
                                <ul className="cowork-list">
                                        <li> If you are interested in our Consultation</li>
                                        <li> CONTACT US AT</li>
                                        <li>greenspacegoa@gmail.com</li>
                                        <li>+919049905310</li>
                                </ul>
                            </span>
                            
                </div>
            </section>
        )
    }
}
