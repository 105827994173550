import React, { Component } from 'react'
import Title from './Title'
import Hero from './Hero';


export default class TextImageRightgasl extends Component {
    state={
        services:
            {
                title:"Our Vision",
                info:`Driven by values to help achieving a greener future for all, We live by the philosophy of eco tourism. We at GreenSpace are trying to bring like minded people and businesses together to drive a new green revolution among the backpacker community. Collectively, we can move away 
                from what harms our environment to a more sustainable way of
                travel and living. Let's try to build an ecosystem of green sustainable travelling.`,
                info2: `Green Space Camp and Co-working Hostel is located in Anjuna in the Goa region, 5.5 km from Anjuna Flea Market and 1.9 km from St. Michael's Church.
                The whole idea of naming it Green Space was to inculcate the habit of being eco-friendly and providing a safe haven for nature lovers. We are committed to offering you an amazing experience here with our concept of camping. With an old Portuguese house as backdrop, the camps are set in the garden with fully functional common areas, washrooms and other amenities.
        
                An in-house cafe, Natti's Naturals,  serving organic and healthy food complements our vision.
                
                With an array of activities planned throughout the week, we will make sure that you love every moment of your stay here.
                
                Let the greenness inside you flow out into the space.`
            }
        
    }
    render() {
        return (
            <section className="services">
                <Title title="Green and Sustainable Travelling" />
                <div className="services-center">
                            <span className="services">
                            
                               <Hero hero="gaslHero" />
                                
                            </span>
                            <span className="services">
                                <div className="services">
                                <h6>{this.state.services.title}</h6>
                                <p>{this.state.services.info}</p>
                                <br />
                                <p>{this.state.services.info2}</p>
                                </div>
                            </span>
                </div>
            </section>
        )
    }
}
